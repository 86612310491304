<template>
  <div>
    <nav-bar />
    <debate-details/>
    <Footer />
  </div>
</template>

<script>
import DebateDetails from '../DebateDetails.vue';
import Footer from "../Footer.vue";
import NavBar from "../NavBar.vue";
export default {
  components: { NavBar, Footer, DebateDetails },
};
</script>

<style lang="scss" scoped>
</style>
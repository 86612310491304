<template>
  <div class="hero-bg">
    <section class="text-center">
      <h2 class="pb-5 lead">
        {{ title ? title : 'Debates'}}
      </h2>
      <span >
        Join seasoned businessmen and women in exciting debate about the future
        of the continent
      </span>
    </section>
  </div>
</template>

<script>
export default {
  props:['title']
};
</script>

<style  scoped>
.hero-bg {
  background: url("../../../assets/new/Debates.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 250px;
  background-repeat: no-repeat;
  position: relative;
}

section{
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
}
.lead{
  font-weight: bolder;
  font-size: 3em;
}
span{
  font-weight: bold;
}
</style>
<template>
  <div>
    <div class="hero-bg">
      <section class="text-center">
        <h2 class="pb-5 lead">{{ debate.topic }}</h2>
        <span class="text-white
        ">
          Join seasoned businessmen and women in exciting debate about the
          future of the continent
        </span>
      </section>
    </div>
    
    <div class="container-fluid trending_debates text-dark">
      <section class="row" v-if="!isLoading">
        <div class="col-sm-10 text-center">
          <div class="col-xs-12 col-sm-12 py-3">
            <div>
              <div>
                <p v-if="author" class="blog-post-meta pb-4">
                  {{ author.date && author.date }} by {{ author.author }}
                </p>
                <p v-if="!author" class="blog-post-meta pb-4">
                  {{ debate.created_at && debate.created_at | moment }} by
                  <a href="#"
                    >{{ debate.created_by && debate.created_by[0].first_name }}
                    {{ debate.created_by && debate.created_by[0].last_name }}</a
                  >
                </p>
                <img
                  v-if="author"
                  class="img-thumbnail"
                  :src="author.image"
                  alt=""
                />
                <img
                  v-if="debate.pic"
                  class="img-thumbnail"
                  :src="debate.pic"
                  alt=""
                />
                <p class="pt-4 text-justify">{{ debate.body }}</p>
              </div>
              <div>
                <button
                  class="mx-3 btn btn-info"
                  v-if="debate.thesis.length == 0"
                  @click="addLeadOpinion(debate.id)"
                >
                  ADD LEAD OPINION
                </button>
                <div class="row" v-if="author">
                  <div class="col-12">
                    <hr />
                    <p>
                      <strong class="text-danger" style="font-size: 1.6em"
                        >Note</strong
                      >
                      This article first appears on
                      <a target="_blank" :href="author.source">{{
                        author.sourcename
                      }}</a
                      >, and is reproduced here on a fair use basis. Where
                      <a target="_blank" :href="author.source">{{
                        author.sourcename
                      }}</a>
                      is the original place where it was published.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DebateHero from './Debates/Debate-hero'
import authors from '../../helpers/debates'

export default {
  components: {DebateHero},
  filters: {
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
      debate: {},
      author: {},
      isLoading: false,
    };
  },
  methods: {
    addLeadOpinion(id) {
      const user = this.$session.get("entity");
      this.$router.replace(`/debates/${id}/add_lead_opinion`);
    },
    fetchDebateDetails() {
      this.isLoading = true;
      this.$store
        .dispatch("fetchApprovedDebateArticle")
        .then((response) => {
          const data = response.data.find(
            (debate) => debate.id == this.$route.params.debateId
          );
          this.debate = data;
          if (!data) {
            this.$router.replace({ name: "pageNotfound" });
          }
          const author = authors.find(
            (author) => author.id == this.$route.params.debateId
          );
          this.author = author;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          //     if(err?.response?.data == undefined) {
          //     this.toast({
          //         message:'Network error. Check network connection and try again.',
          //         type:'error'
          //     })
          // }
        });
    },
  },
  mounted() {
    this.$store
      .dispatch("fetchLeadersVision")
      .then((response) => console.log(response));
    this.fetchDebateDetails();
  },
};
</script>

<style  scoped>
.trending_debates {
  padding-bottom: 7em;
  padding-right: 12%;
  padding-left: 12%;
}

.hero-bg {
  background: url("../../assets/new/Debates.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 250px;
  background-repeat: no-repeat;
  position: relative;
}

section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
}
.lead{
  font-weight: bolder;
  font-size: 3em;
  color: #fff;
}
span{
  font-weight: bold;
}
</style>